import ConfirmationAlert from '@/components/ConfirmationAlert/ConfirmationAlert.vue'
import Container from '@/components/Container.vue'
import EmptyState from '@/components/EmptyState.vue'
import Footer from '@/components/Footer.vue'
import EditorInput from '@/components/FormComponents/EditorInput.vue'
import FormButton from '@/components/FormComponents/FormButton.vue'
import PasswordInput from '@/components/FormComponents/PasswordInput.vue'
import SelectInput from '@/components/FormComponents/SelectInput.vue'
import TextAreaInput from '@/components/FormComponents/TextAreaInput.vue'
import TextInput from '@/components/FormComponents/TextInput.vue'
import Header from '@/components/Header.vue'
import HomeCard from '@/components/HomeCard.vue'
import LoadingContainer from '@/components/LoadingContainer.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import Navbar from '@/components/Navbar.vue'
import SearchBar from '@/components/SearchBar.vue'
import Sidebar from '@/components/Sidebar.vue'
import TableHead from '@/components/TableHead.vue'
import Button from '@/components/Button.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@popperjs/core'
import 'bootstrap'
import 'jquery'
import "moment/locale/pt-br"
import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/styles.scss'
import './assets/theme/theme-dark.scss'
import RadioInput from './components/FormComponents/RadioInput.vue'
import ImageCropper from './components/ImageCropper/ImageCropper.vue'
import YoutubeInput from './components/YoutubeInput/YoutubeInput.vue'
import router from './router'
import paths from './router/paths'
import LandingService from './services/LandingService'
import UserService from './services/UserService'
import { store } from './store'
import { EventBus } from './utils/EventBus'
import './utils/Prototypes/prototypeFunctions'
import ExamsService from './services/ExamsService'
import 'vue3-carousel/dist/carousel.css'
import BundlesService from './services/BundlesService'

const app = createApp(App)
app.config.globalProperties.$eventBus = EventBus
app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$landingService = new LandingService()
app.config.globalProperties.$examsService = new ExamsService()
app.config.globalProperties.$bundlesService = new BundlesService()
app.config.globalProperties.$paths = paths

app.component('Sidebar', Sidebar)
app.component('Navbar', Navbar)
app.component('LoadingContainer', LoadingContainer)
app.component('EmptyState', EmptyState)
app.component('Container', Container)
app.component('SearchBar', SearchBar)
app.component('TextInput', TextInput)
app.component('SelectInput', SelectInput)
app.component('TextAreaInput', TextAreaInput)
app.component('PasswordInput', PasswordInput)
app.component('EditorInput', EditorInput)
app.component('FormButton', FormButton)
app.component('TableHead', TableHead)
app.component('ModalComponent', ModalComponent)
app.component('Header', Header)
app.component('ConfirmationAlert', ConfirmationAlert)
app.component('HomeCard', HomeCard)
app.component('ImageCropper', ImageCropper)
app.component('RadioInput', RadioInput)
app.component('YoutubeInput', YoutubeInput)
app.component('Footer', Footer)
app.component('Button', Button)
app
    .use(store)
    .use(router)
    .mount('#app')
